/*
 *          M""""""""`M            dP
 *          Mmmmmm   .M            88
 *          MMMMP  .MMM  dP    dP  88  .dP   .d8888b.
 *          MMP  .MMMMM  88    88  88888"    88'  `88
 *          M' .MMMMMMM  88.  .88  88  `8b.  88.  .88
 *          M         M  `88888P'  dP   `YP  `88888P'
 *          MMMMMMMMMMM    -*-  Created by Zuko  -*-
 *
 *          * * * * * * * * * * * * * * * * * * * * *
 *          * -    - -   F.R.E.E.M.I.N.D   - -    - *
 *          * -  Copyright © 2024 (Z) Programing  - *
 *          *    -  -  All Rights Reserved  -  -    *
 *          * * * * * * * * * * * * * * * * * * * * *
 */

import React from 'react';

const PointOrder02 = (props) => {
    return (
        <ul>
            <li><a href={props.href}>{props.li1}</a></li>
            <li className="active"><a href={props.href}>{props.li2}</a></li>
            <li><a href={props.href}>{props.li3}</a></li>
            <li><a href={props.href}>{props.li4}</a></li>
            <li><a href={props.href}>{props.li5}</a></li>
        </ul>
    );
};

export default PointOrder02;
