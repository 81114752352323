/*
 *          M""""""""`M            dP
 *          Mmmmmm   .M            88
 *          MMMMP  .MMM  dP    dP  88  .dP   .d8888b.
 *          MMP  .MMMMM  88    88  88888"    88'  `88
 *          M' .MMMMMMM  88.  .88  88  `8b.  88.  .88
 *          M         M  `88888P'  dP   `YP  `88888P'
 *          MMMMMMMMMMM    -*-  Created by Zuko  -*-
 *
 *          * * * * * * * * * * * * * * * * * * * * *
 *          * -    - -   F.R.E.E.M.I.N.D   - -    - *
 *          * -  Copyright © 2024 (Z) Programing  - *
 *          *    -  -  All Rights Reserved  -  -    *
 *          * * * * * * * * * * * * * * * * * * * * *
 */

import React from 'react';

const FunFact = ({ data }) => {
    const { contentName, contentNumber, plus, img } = data;

    return (
        <div className="funfact2 d-flex align-items-center">
            <div className="fun_img">
                <img src={img} alt="img" />
            </div>
            <div className="funfact_content">
                <p>{contentName}</p>
                <div className="d-flex align-items-center"><h2 className="fun-number">{contentNumber}</h2> <span>{plus}</span></div>
            </div>
        </div>
    );
};

export default FunFact;
