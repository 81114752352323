/*
 *          M""""""""`M            dP
 *          Mmmmmm   .M            88
 *          MMMMP  .MMM  dP    dP  88  .dP   .d8888b.
 *          MMP  .MMMMM  88    88  88888"    88'  `88
 *          M' .MMMMMMM  88.  .88  88  `8b.  88.  .88
 *          M         M  `88888P'  dP   `YP  `88888P'
 *          MMMMMMMMMMM    -*-  Created by Zuko  -*-
 *
 *          * * * * * * * * * * * * * * * * * * * * *
 *          * -    - -   F.R.E.E.M.I.N.D   - -    - *
 *          * -  Copyright © 2024 (Z) Programing  - *
 *          *    -  -  All Rights Reserved  -  -    *
 *          * * * * * * * * * * * * * * * * * * * * *
 */

import { RouterProvider } from 'react-router-dom';
import 'react-select2-wrapper/css/select2.css';
import 'swiper/css';
import 'swiper/css/autoplay';
import "swiper/css/effect-coverflow";
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './App.css';
import ThemeSwitcher from './Pages/Shared/ThemeSwitcher';
import { routes } from './Routes/Router';
import './assets/css/style.css';
import $ from 'jquery';
import 'select2';
globalThis.jQuery = $;
globalThis.$ = $;
import {ApiClientFactory} from './services/apiClient.js';
globalThis.apiClient = ApiClientFactory.getInstance(globalThis.API_BASE_URL, {debug: true});
function App() {
  return (
    <div>
      <ThemeSwitcher/>
      <RouterProvider router={routes} /> 
    </div>
  );
}

export default App;
// adsd commentß
