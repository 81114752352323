/*
 *          M""""""""`M            dP
 *          Mmmmmm   .M            88
 *          MMMMP  .MMM  dP    dP  88  .dP   .d8888b.
 *          MMP  .MMMMM  88    88  88888"    88'  `88
 *          M' .MMMMMMM  88.  .88  88  `8b.  88.  .88
 *          M         M  `88888P'  dP   `YP  `88888P'
 *          MMMMMMMMMMM    -*-  Created by Zuko  -*-
 *
 *          * * * * * * * * * * * * * * * * * * * * *
 *          * -    - -   F.R.E.E.M.I.N.D   - -    - *
 *          * -  Copyright © 2024 (Z) Programing  - *
 *          *    -  -  All Rights Reserved  -  -    *
 *          * * * * * * * * * * * * * * * * * * * * *
 */

import React from 'react';

const ProjectCard09 = ({ data }) => {
    const { img, details } = data;

    return (
        <div className='grid-item'>
            <div className="thumb">
                <img className="item_image" src={img} alt="" />
                <div className="works-info">
                    <div className="label-text">
                        <h6><a href="#">Lake Cabin</a></h6>
                        <h5><a href="/project-details">{details}</a></h5>
                        <div className="details_link"><a href="/project-details"><span className="link_text">View Details</span> <span className="link_icon"><span className="line"></span> <span className="circle"></span><span className="dot"></span></span></a></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectCard09;
