/*
 *          M""""""""`M            dP
 *          Mmmmmm   .M            88
 *          MMMMP  .MMM  dP    dP  88  .dP   .d8888b.
 *          MMP  .MMMMM  88    88  88888"    88'  `88
 *          M' .MMMMMMM  88.  .88  88  `8b.  88.  .88
 *          M         M  `88888P'  dP   `YP  `88888P'
 *          MMMMMMMMMMM    -*-  Created by Zuko  -*-
 *
 *          * * * * * * * * * * * * * * * * * * * * *
 *          * -    - -   F.R.E.E.M.I.N.D   - -    - *
 *          * -  Copyright © 2024 (Z) Programing  - *
 *          *    -  -  All Rights Reserved  -  -    *
 *          * * * * * * * * * * * * * * * * * * * * *
 */

import React from 'react';
import ProductDetails from './ProductDetails';
import ProductView from './ProductView';
import RelatedProducts from './RelatedProducts';

// export const SingleProductContext = createContext();


const ShopContainer04 = ({ selectedProduct }) => {

    return (
        <main className="wrapper">
            <ProductView />
            <ProductDetails />
            <RelatedProducts />
            {/* <ProductView productView={selectedProduct} />
            <ProductDetails projectDetails={selectedProduct} />
            <RelatedProducts relatedProducts={selectedProduct} /> */}
        </main >
    );
};

export default ShopContainer04;
