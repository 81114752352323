/*
 *          M""""""""`M            dP
 *          Mmmmmm   .M            88
 *          MMMMP  .MMM  dP    dP  88  .dP   .d8888b.
 *          MMP  .MMMMM  88    88  88888"    88'  `88
 *          M' .MMMMMMM  88.  .88  88  `8b.  88.  .88
 *          M         M  `88888P'  dP   `YP  `88888P'
 *          MMMMMMMMMMM    -*-  Created by Zuko  -*-
 *
 *          * * * * * * * * * * * * * * * * * * * * *
 *          * -    - -   F.R.E.E.M.I.N.D   - -    - *
 *          * -  Copyright © 2024 (Z) Programing  - *
 *          *    -  -  All Rights Reserved  -  -    *
 *          * * * * * * * * * * * * * * * * * * * * *
 */

import Isotope from 'isotope-layout';
import React, { useEffect } from 'react';
import RelatedProjectCard from './RelatedProjectCard';

const RelatedProjects = () => {
    useEffect(() => {
        Isotope.current = new Isotope('.grid', {
            itemSelector: '.grid-item',
            percentPosition: true,
            // layoutMode: 'packery',
            transformsEnabled: true,
            transitionDuration: "700ms",
            resize: true,
            fitWidth: true,
            columnWidth: '.grid-sizer',
        })

        // cleanup
        return () => Isotope.current.destroy();

    }, []);
    return (
        <div className="related-posts">
            <h6 className="widget-title mb-2">
                More Projects
                <span className="title-line"></span>
            </h6>
            <div className="grid grid-3 gutter-15 clearfix">
                <div className="grid-sizer"></div>
                <div className="grid-item residences">
                    <RelatedProjectCard img='images/portfolio/4.jpg' name='California young menz club' />
                </div>
                <div className="grid-item interiors">
                    <RelatedProjectCard img='images/portfolio/2.jpg' name='California young menz club' />
                </div>
                <div className="grid-item residences">
                    <RelatedProjectCard img='images/portfolio/3.jpg' name='California young menz club' />
                </div>
            </div>
        </div>
    );
};

export default RelatedProjects;
