/*
 *          M""""""""`M            dP
 *          Mmmmmm   .M            88
 *          MMMMP  .MMM  dP    dP  88  .dP   .d8888b.
 *          MMP  .MMMMM  88    88  88888"    88'  `88
 *          M' .MMMMMMM  88.  .88  88  `8b.  88.  .88
 *          M         M  `88888P'  dP   `YP  `88888P'
 *          MMMMMMMMMMM    -*-  Created by Zuko  -*-
 *
 *          * * * * * * * * * * * * * * * * * * * * *
 *          * -    - -   F.R.E.E.M.I.N.D   - -    - *
 *          * -  Copyright © 2024 (Z) Programing  - *
 *          *    -  -  All Rights Reserved  -  -    *
 *          * * * * * * * * * * * * * * * * * * * * *
 */

import React from 'react';

const PaginationDiv02 = () => {
    return (
        <div className="pagination-div">
            <ul className="pagination">
                <li><a className="page-number current" href="#">1</a></li>
                <li><a className="page-number" href="#">2</a></li>
                <li><a className="page-number" href="#">3</a></li>
                <li><a className="page-number" href="#">4</a></li>
                <li><a className="page-number" href="#">...</a></li>
                <li><a className="page-number" href="#">8</a></li>
                <li><a href="#"><i className="bi bi-chevron-right"></i></a></li>
            </ul>
        </div>
    );
};

export default PaginationDiv02;
