/*
 *          M""""""""`M            dP
 *          Mmmmmm   .M            88
 *          MMMMP  .MMM  dP    dP  88  .dP   .d8888b.
 *          MMP  .MMMMM  88    88  88888"    88'  `88
 *          M' .MMMMMMM  88.  .88  88  `8b.  88.  .88
 *          M         M  `88888P'  dP   `YP  `88888P'
 *          MMMMMMMMMMM    -*-  Created by Zuko  -*-
 *
 *          * * * * * * * * * * * * * * * * * * * * *
 *          * -    - -   F.R.E.E.M.I.N.D   - -    - *
 *          * -  Copyright © 2024 (Z) Programing  - *
 *          *    -  -  All Rights Reserved  -  -    *
 *          * * * * * * * * * * * * * * * * * * * * *
 */

import React, {useRef} from 'react';
import {useForm} from 'react-hook-form';
import defaultContent from '@/contents/ComponentFormContent';
import "trix/dist/trix";
import {TrixEditor} from 'react-trix';
import 'trix/dist/trix.css';

const Form = ({
    frmAction,
    onSubmit,
    frmMultipart = defaultContent.frmMultipart,
    frmMethod = defaultContent.frmMethod,
    placeholders = defaultContent.placeholders,
    submitBtnTxt = defaultContent.submitBtnTxt,
    serverErrors = {}
}) => {
    const {
        register,
        handleSubmit,
        formState: {errors},
        setError
    } = useForm();

    const inputRef = useRef();
    
    // Set server-side errors if any
    React.useEffect(() => {
        Object.entries(serverErrors).forEach(([field, messages]) => {
            setError(field, {
                type: 'server',
                message: Array.isArray(messages) ? messages[0] : messages
            });
        });
    }, [serverErrors, setError]);

    const onEditorChange = (html, text) => {
        inputRef.current.value = html;
    };

    const handleFormSubmit = (data) => {
        // Include the Trix editor content
        data.content = inputRef.current.value;
        onSubmit(data);
    };

    return (
        <div className="home_contact">
            <form action={frmAction} 
                  method={(frmMethod === 'GET') ? 'GET' : 'POST'}
                  onSubmit={handleSubmit(handleFormSubmit)} 
                  encType={frmMultipart ? 'multipart/form-data' : 'x-www-form-urlencoded'}>
                
                <div className="form-group">
                    <input
                        type={'text'}
                        className={`form-control form-control-lg ${errors.name ? 'is-invalid' : ''}`}
                        {...register('name', {
                            required: 'Name is required',
                            minLength: {
                                value: 5,
                                message: 'Name must be at least 5 characters'
                            },
                            pattern: {
                                value: /^[A-Za-z\s]+$/,
                                message: 'Name must contain only letters'
                            }
                        })}
                        placeholder={placeholders['name']}
                    />
                    {errors.name && (
                        <div className="invalid-feedback d-block">{errors.name.message}</div>
                    )}
                </div>

                <div className="form-group">
                    <input
                        className={`form-control form-control-lg ${errors.phone ? 'is-invalid' : ''}`}
                        {...register('phone', {
                            pattern: {
                                value: /^[0-9]+$/,
                                message: 'Please enter a valid phone number'
                            }
                        })}
                        type="tel"
                        placeholder={placeholders['phone']}
                    />
                    {errors.phone && (
                        <div className="invalid-feedback d-block">{errors.phone.message}</div>
                    )}
                </div>

                <div className="form-group">
                    <input
                        className={`form-control form-control-lg ${errors.email ? 'is-invalid' : ''}`}
                        {...register('email', {
                            required: 'Email is required',
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: 'Please enter a valid email address'
                            }
                        })}
                        type="email"
                        placeholder={placeholders['email']}
                    />
                    {errors.email && (
                        <div className="invalid-feedback d-block">{errors.email.message}</div>
                    )}
                </div>

                <div className="form-group">
                    <input type="hidden" ref={inputRef} name="content" />
                    <TrixEditor
                        className={`form-control pt-4 ${errors.content ? 'is-invalid' : ''}`}
                        onChange={onEditorChange}
                        placeholder={placeholders['content']}
                    />
                    {errors.content && (
                        <div className="invalid-feedback d-block">{errors.content.message}</div>
                    )}
                </div>

                <div className="btn_group">
                    <button type="submit" className="btn olive">{submitBtnTxt}</button>
                </div>
            </form>
        </div>
    );
};

export default Form;
