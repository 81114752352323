/*
 *          M""""""""`M            dP
 *          Mmmmmm   .M            88
 *          MMMMP  .MMM  dP    dP  88  .dP   .d8888b.
 *          MMP  .MMMMM  88    88  88888"    88'  `88
 *          M' .MMMMMMM  88.  .88  88  `8b.  88.  .88
 *          M         M  `88888P'  dP   `YP  `88888P'
 *          MMMMMMMMMMM    -*-  Created by Zuko  -*-
 *
 *          * * * * * * * * * * * * * * * * * * * * *
 *          * -    - -   F.R.E.E.M.I.N.D   - -    - *
 *          * -  Copyright © 2024 (Z) Programing  - *
 *          *    -  -  All Rights Reserved  -  -    *
 *          * * * * * * * * * * * * * * * * * * * * *
 */

import CheckoutForm from "../components/CheckoutForm";
import PlaceOrder from "../components/PlaceOrder";
import WidgetTitle from "../components/WidgetTitle";

const Checkout = () => {
    return (
        <section className="shopping_cart bg-dark-200">
            <div className="container">
                <div className="shipping_form">
                    <form>
                        <div className="have_coupon">
                            <div className="form-group">
                                <label htmlFor="couponcode">Have a coupon?</label>
                                <input type="text" className="form-control" id="couponcode" name="couponcode" placeholder="Enter Coupon Code" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-7 pe-5">
                                <WidgetTitle title='Billing Address' className='widget-title' />
                                <CheckoutForm />
                            </div>
                            <div className="col-lg-5">
                                <PlaceOrder />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default Checkout;
