/**
 * --------------------------------------------------------------------------
 *
 * --------------------------------------------------------------------------
 * @PROJECT    : Lalita
 * @AUTHOR     : Zuko <https://github.com/tansautn>
 * @LINK       : https://www.zuko.pro/
 * @FILE       : apiClient.js
 * @CREATED    : 13:20 , 03/Nov/2024
 */
// Factory for singleton instance
export const ApiClientFactory = {
  _instance : null,
  _all      : [],
  /**
   * Get or create singleton instance
   * @param {string} baseURL
   * @param {object} options
   * @returns {ApiClient}
   */
  getInstance(baseURL, options = {}) {
    if(!this._instance) {
      this._instance = new ApiClient(baseURL, options);
    }
    return this._instance;
  },

  /**
   * Reset instance (useful for testing)
   */
  resetInstance() {
    this._instance = null;
  }
};

export class ApiClient {
  constructor(baseURL, options = {}) {
    this.baseURL = baseURL.replace(/\/$/, '');
    this.options = {
      headers             : {
        'Content-Type' : 'application/json',
        ...options.headers
      },
      credentials         : options.credentials || 'include',
      responseInterceptor : options.responseInterceptor || null,
      errorHandler        : options.errorHandler || null,
      // Default pagination
      defaultLimit : options.defaultLimit || 20,
      maxLimit     : options.maxLimit || 200,
      // Debug options
      debug          : options.debug || false,
      debugRequests  : options.debugRequests || false,
      debugResponses : options.debugResponses || false
    };
    if(1) {
      this._debugLog('Info', this.baseURL, this.options);
    }
    this.options.debug = true;
  }

  /**
   * Normalize endpoint to never start with slash
   */
  _normalizeEndpoint(endpoint) {
    let url = `${this.baseURL}`.replace(/\/$/, '');
// eslint-disable-next-line no-undef
    const p = globalThis.API_BASE_PREFIX;
    endpoint = endpoint.replace(/^\/+/, '');
    console.info(p);
    console.log(endpoint);
    console.warn(url);
    if(url.endsWith(p) && endpoint.startsWith(p)) {
      endpoint = endpoint.substring(p.length);
    }
    return `${url}/${endpoint.replace(/^\/+/, '')}`;
  }

  /**
   * Check if debugging is enabled either via options or global flags
   */
  _isDebugEnabled() {
    //noinspection JSUnresolvedReference
    return this.options.debug ||
           globalThis.__apiClientDebug ||
           globalThis.__isDebug;
  }

  /**
   * Debug logger with consistent formatting
   */
  _debugLog(type, ...args) {
    if(!this._isDebugEnabled()) {
      return;
    }

    const styles = {
      request  : 'color: #9b59b6;',  // Purple for requests
      response : 'color: #2ecc71;', // Green for responses
      error    : 'color: #e74c3c;'     // Red for errors
    };

    console.groupCollapsed(`%cApiClient ${type}`, styles[type.toLowerCase()]);
    args.forEach(arg => {
      if(typeof arg === 'string') {
        console.log(arg);
      }
      else {
        console.dir(arg, {depth : null});
      }
    });
    console.groupEnd();
  }

  // Convert API response to ApiError if needed
  _handleResponse(response) {
    if(!response.ok) {
      const {error, message} = response;
      throw new ApiError(message || 'API Error', error?.code, response.errors);
    }
    return response.data;
  }

  async request(method, endpoint, data = null, options = {}) {
    endpoint = this._normalizeEndpoint(endpoint);
    const {
            params = {},
            with : eagerLoad,
            sort,
            limit,
            ...customOptions
          } = options;

    // Build query parameters
    const queryParams = new URLSearchParams();

    // Handle eager loading
    if(eagerLoad) {
      queryParams.append('with', Array.isArray(eagerLoad) ? eagerLoad.join(',') : eagerLoad);
    }

    // Handle sorting
    if(sort) {
      queryParams.append('sortBy', sort.field || 'id');
      queryParams.append('sortDirection', sort.direction || 'asc');
    }

    // Handle pagination
    if(limit) {
      const safeLimit = Math.min(limit, this.options.maxLimit);
      queryParams.append('limit', safeLimit);
    }

    // Add custom params
    Object.entries(params).forEach(([key, value]) => {
      queryParams.append(key, value);
    });

    const url = `${endpoint}${queryParams.toString() ? '?' + queryParams.toString() : ''}`;

    try {
      // Wrap data in 'data' key for mutations
      const body = (method !== 'GET' && data) ? JSON.stringify({data}) : undefined;

      const requestConfig = {
        method,
        headers     : {
          ...this.options.headers,
          ...customOptions.headers
        },
        credentials : this.options.credentials,
        body
      };

      // Debug request
      if(this._isDebugEnabled()) {
        this._debugLog('Request', {
          url,
          method,
          headers : requestConfig.headers,
          body    : body ? JSON.parse(body) : undefined
        });
      }
      if(['POST', 'PUT', 'PATCH'].includes(method) && body === undefined) {
        throw new ApiError('No data provided', -5);
      }
      const response = await fetch(url, requestConfig);
      const result = await response.json();

      // Debug response
      if(this._isDebugEnabled()) {
        this._debugLog('Response', {
          status : response.status,
          data   : result
        });
      }

      // Handle response
      if(this.options.responseInterceptor) {
        const intercepted = this.options.responseInterceptor(result);
        if(intercepted) {
          return this._handleResponse(intercepted);
        }
      }

      return this._handleResponse(result);

    }
    catch(error) {
      if(this._isDebugEnabled()) {
        this._debugLog('Error', error);
      }

      if(this.options.errorHandler) {
        this.options.errorHandler(error);
      }
      throw error;
    }
  }

  // Enhanced GET with pagination support
  async get(endpoint, options = {}) {
    return this.request('GET', endpoint, null, options);
  }

  async post(endpoint, data, options = {}) {
    return this.request('POST', endpoint, data, options);
  }

  async put(endpoint, data, options = {}) {
    return this.request('PUT', endpoint, data, options);
  }

  async patch(endpoint, data, options = {}) {
    return this.request('PATCH', endpoint, data, options);
  }

  async delete(endpoint, options = {}) {
    return this.request('DELETE', endpoint, null, options);
  }

  // Pagination helper
  async paginate(endpoint, options = {}) {
    const {page = 1, ...rest} = options;
    return this.get(endpoint, {
      params : {page},
      ...rest
    });
  }
}

// Enhanced ApiError to match backend error structure
export class ApiError extends Error {
  constructor(message, code = -1, errors = null) {
    super(message);
    this.name = 'ApiError';
    this.code = code;
    this.errors = errors;
  }
}

//
//// Usage examples
//const api = new ApiClient('https://api.example.com', {
//  headers: {
//    'Authorization': 'Bearer token'
//  },
//  defaultLimit: 20,
//  maxLimit: 200
//});
//
//// Example usages:
//// Basic CRUD
//api.get('/posts/1', { with: ['author', 'comments'] })
//   .then(data => console.log(data))
//   .catch(e => console.error(e.message));
//
//// Create with data wrapper
//api.post('/posts', {
//  title: 'New Post',
//  content: 'Content'
//})
//   .then(data => console.log(data))
//   .catch(e => console.error(e.message));
//
//// Pagination with sorting
//api.paginate('/posts', {
//  page: 2,
//  limit: 20,
//  sort: { field: 'created_at', direction: 'desc' },
//  with: ['author']
//})
//   .then(data => {
//     console.log(data.current_page);
//     console.log(data.total);
//     console.log(data.data); // The actual items
//   });
