/*
 *          M""""""""`M            dP
 *          Mmmmmm   .M            88
 *          MMMMP  .MMM  dP    dP  88  .dP   .d8888b.
 *          MMP  .MMMMM  88    88  88888"    88'  `88
 *          M' .MMMMMMM  88.  .88  88  `8b.  88.  .88
 *          M         M  `88888P'  dP   `YP  `88888P'
 *          MMMMMMMMMMM    -*-  Created by Zuko  -*-
 *
 *          * * * * * * * * * * * * * * * * * * * * *
 *          * -    - -   F.R.E.E.M.I.N.D   - -    - *
 *          * -  Copyright © 2024 (Z) Programing  - *
 *          *    -  -  All Rights Reserved  -  -    *
 *          * * * * * * * * * * * * * * * * * * * * *
 */

import React from 'react';

const ServiceCard02 = (props) => {

    return (
        <div className="icon_box type-2">
            <img src={props.img01} alt="img" className="post-bg" />
            <div className="icon_box_inner">
                <img src={props.img02} alt="Icon Box" />
                <h4 className="text-white"><a href="/service-1">{props.title}</a></h4>
                <p className="text-gray-600">{props.text}</p>
                <div className="arrow_effect">
                    <a href="/service-1"><span className="crossline1"></span><span className="crossline2"></span></a>
                </div>
            </div>
        </div>

    );
};

export default ServiceCard02;
