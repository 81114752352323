/*
 *          M""""""""`M            dP
 *          Mmmmmm   .M            88
 *          MMMMP  .MMM  dP    dP  88  .dP   .d8888b.
 *          MMP  .MMMMM  88    88  88888"    88'  `88
 *          M' .MMMMMMM  88.  .88  88  `8b.  88.  .88
 *          M         M  `88888P'  dP   `YP  `88888P'
 *          MMMMMMMMMMM    -*-  Created by Zuko  -*-
 *
 *          * * * * * * * * * * * * * * * * * * * * *
 *          * -    - -   F.R.E.E.M.I.N.D   - -    - *
 *          * -  Copyright © 2024 (Z) Programing  - *
 *          *    -  -  All Rights Reserved  -  -    *
 *          * * * * * * * * * * * * * * * * * * * * *
 */

import React from 'react';
import PageBanner03 from '../../containers/PageBanner03';
import ProjectGallery03 from '../../containers/ProjectContainers/ProjectGallery03';

const Project03 = () => {
    return (
        <>
            <PageBanner03 title="Projects" activePage="Projects" />
            <ProjectGallery03 text='Load More' className='btn gray' />
        </>
    );
};

export default Project03;
