/*
 *          M""""""""`M            dP
 *          Mmmmmm   .M            88
 *          MMMMP  .MMM  dP    dP  88  .dP   .d8888b.
 *          MMP  .MMMMM  88    88  88888"    88'  `88
 *          M' .MMMMMMM  88.  .88  88  `8b.  88.  .88
 *          M         M  `88888P'  dP   `YP  `88888P'
 *          MMMMMMMMMMM    -*-  Created by Zuko  -*-
 *
 *          * * * * * * * * * * * * * * * * * * * * *
 *          * -    - -   F.R.E.E.M.I.N.D   - -    - *
 *          * -  Copyright © 2024 (Z) Programing  - *
 *          *    -  -  All Rights Reserved  -  -    *
 *          * * * * * * * * * * * * * * * * * * * * *
 */

import React from 'react';
import PropTypes from 'prop-types';
import defaultContent from '@/contents/PageThankyouContent';

const ThankYou = ({
    // Main content props
    heading = defaultContent.heading,
    title = defaultContent.title,
    message = defaultContent.message,
    
    // Search functionality
    showSearch = false,
    onSearch = () => {},
    searchPlaceholder = defaultContent.searchPlaceholder,
    
    // Buttons configuration
    buttons = defaultContent.buttons,
    
    // Optional custom classes
    className = '',
    contentClassName = ''
}) => {
    const handleSearch = (e) => {
        e.preventDefault();
        const keyword = e.target.search.value;
        onSearch(keyword);
    };

    return (
        <>
            <div className="header-layer-bg"></div>

            <main className={`wrapper ${className}`}>
                <section className="error-page">
                    <div className="container">
                        <div className="row">
                            <div className="col col-lg-10 offset-lg-1">
                                <div className={`error-content text-center ${contentClassName}`}>
                                    <div className="heading">{heading}</div>
                                    <h1>{title}</h1>
                                    <p>{message}</p>

                                    {showSearch && (
                                        <form className="search_form" onSubmit={handleSearch}>
                                            <input 
                                                type="text" 
                                                name="search" 
                                                className="keyword form-control" 
                                                placeholder={searchPlaceholder} 
                                            />
                                            <button type="submit" className="form-control-submit">
                                                <i className="bi bi-arrow-right"></i>
                                            </button>
                                        </form>
                                    )}

                                    {buttons.length > 0 && (
                                        <div className="btn_group">
                                            {buttons.map((button, index) => (
                                                button.onClick ? (
                                                    <button
                                                        key={index}
                                                        className={`btn ${button.variant || ''}`}
                                                        onClick={button.onClick}
                                                    >
                                                        {button.text}
                                                    </button>
                                                ) : (
                                                    <a
                                                        key={index}
                                                        href={button.href}
                                                        className={`btn ${button.variant || ''}`}
                                                    >
                                                        {button.text}
                                                    </a>
                                                )
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
};

ThankYou.propTypes = {
    heading: PropTypes.string,
    title: PropTypes.string,
    message: PropTypes.string,
    showSearch: PropTypes.bool,
    onSearch: PropTypes.func,
    searchPlaceholder: PropTypes.string,
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string.isRequired,
            href: PropTypes.string,
            variant: PropTypes.string,
            onClick: PropTypes.func
        })
    ),
    className: PropTypes.string,
    contentClassName: PropTypes.string
};

export default ThankYou;
