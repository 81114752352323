/**
 * --------------------------------------------------------------------------
 *
 * --------------------------------------------------------------------------
 * @PROJECT    : Lalita
 * @AUTHOR     : Zuko <https://github.com/tansautn>
 * @LINK       : https://www.zuko.pro/
 * @FILE       : ContactFormWrapper.jsx
 * @CREATED    : 8:47 , 13/Nov/2024
 */

import React, {useState} from 'react';
import Form from '@/components/Form.jsx';
import ThankYou from '@/Pages/ThankYou.jsx';
import {ApiClientFactory} from '@/services/apiClient.js';


export const ContactFormWrapper = () => {
  const [showThankYou, setShowThankYou] = useState(false);
  const [serverErrors, setServerErrors] = useState({});

  const handleSubmit = async (data) => {
    try {
      setServerErrors({});
      const api = ApiClientFactory.getInstance();
      const rs = await api.post('api/visitor-message', data);

      if (rs.id > 0) {
        setShowThankYou(true);
      }
    } catch (error) {
      if (error.errors) {
        setServerErrors(error.errors);
      } else {
        console.error('Form submission error:', error);
        // Handle other types of errors
        alert('An error occurred while submitting the form. Please try again.');
      }
    }
  };
if(showThankYou){
  return (
  <ThankYou
  heading="Thank You!"
  title="Message Sent Successfully"
  message="We have received your message and will get back to you soon."
  buttons={[
    {
      text: 'Send Another Message',
      variant: 'olive',
      onClick: () => {
        setShowThankYou(false);
        setServerErrors({});
      }
    }
  ]}
  />
  );
}
  return  (
                         <Form
                         onSubmit={handleSubmit}
                         serverErrors={serverErrors}
                         />
                         );
}
