/*
 *          M""""""""`M            dP
 *          Mmmmmm   .M            88
 *          MMMMP  .MMM  dP    dP  88  .dP   .d8888b.
 *          MMP  .MMMMM  88    88  88888"    88'  `88
 *          M' .MMMMMMM  88.  .88  88  `8b.  88.  .88
 *          M         M  `88888P'  dP   `YP  `88888P'
 *          MMMMMMMMMMM    -*-  Created by Zuko  -*-
 *
 *          * * * * * * * * * * * * * * * * * * * * *
 *          * -    - -   F.R.E.E.M.I.N.D   - -    - *
 *          * -  Copyright © 2024 (Z) Programing  - *
 *          *    -  -  All Rights Reserved  -  -    *
 *          * * * * * * * * * * * * * * * * * * * * *
 */

import React from 'react';

// At where displaying content, we just load from content file. So in future we dont need to adjust this file anymore.
import AboutUsContent from '../contents/AboutUs.jsx';
const MissionContainer = () => {
  const { slogan, title, content, headerTitle } = AboutUsContent;
    return (
        <section className="mission">
            <div className="container">
                <div className="mission_top_part">
                    <div className="section-header text-center">
                        <h6 className="text-white text-uppercase">Our MISSION</h6>
                        <p className="text-gray-600">{slogan}</p>
                    </div>
                    <div className="has_line"></div>
                    <img src="images/bg/about_bg.jpg" alt="" />
                </div>

                <div className="mission_bottom_part">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 pe-lg-4">
                            <div className="section-header">
                                <h3 className="text-white text-uppercase border-line">{title}</h3>
                            </div>
                        </div>
                        <div className="col-lg-6 ps-lg-4">
                            <div className="mission_content" dangerouslySetInnerHTML={{ __html: content }}>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MissionContainer;
