/*
 *          M""""""""`M            dP
 *          Mmmmmm   .M            88
 *          MMMMP  .MMM  dP    dP  88  .dP   .d8888b.
 *          MMP  .MMMMM  88    88  88888"    88'  `88
 *          M' .MMMMMMM  88.  .88  88  `8b.  88.  .88
 *          M         M  `88888P'  dP   `YP  `88888P'
 *          MMMMMMMMMMM    -*-  Created by Zuko  -*-
 *
 *          * * * * * * * * * * * * * * * * * * * * *
 *          * -    - -   F.R.E.E.M.I.N.D   - -    - *
 *          * -  Copyright © 2024 (Z) Programing  - *
 *          *    -  -  All Rights Reserved  -  -    *
 *          * * * * * * * * * * * * * * * * * * * * *
 */

import React from 'react';
import { Link } from "react-router-dom";

const CategotyProductItem = ({productItem, handleAddToCart}) => {
    const { id, img, name, badge, newClass, oldClass, price, oldPrice, categoryName } = productItem;

    return (
        <div className="product_item">
            <div className="product_thumb">
                <div className="product_imagebox">
                    <span className={newClass}>{badge}</span>
                    <img className="primary_img" src={img} alt="img" />
                    <div onClick={() => handleAddToCart(productItem)} className="cart_button">
                        <a href="#" className="button">
                            Add to Cart
                        </a>
                    </div>
                </div>
                <div className="product_item_inner">
                    <div className="label_text">
                        <Link to={`/shop-product/${id}`}>
                        <h4 className="product_item_name"><a>{name}</a>
                            <span className="product_item_price"> <del className={oldClass}>{oldPrice}</del> {price}</span>
                            <span className="product_item_price"></span>
                        </h4>
                        </Link>
                        <div className="product_category"><a href="#">{categoryName}</a></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CategotyProductItem;
