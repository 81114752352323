/*
 *          M""""""""`M            dP
 *          Mmmmmm   .M            88
 *          MMMMP  .MMM  dP    dP  88  .dP   .d8888b.
 *          MMP  .MMMMM  88    88  88888"    88'  `88
 *          M' .MMMMMMM  88.  .88  88  `8b.  88.  .88
 *          M         M  `88888P'  dP   `YP  `88888P'
 *          MMMMMMMMMMM    -*-  Created by Zuko  -*-
 *
 *          * * * * * * * * * * * * * * * * * * * * *
 *          * -    - -   F.R.E.E.M.I.N.D   - -    - *
 *          * -  Copyright © 2024 (Z) Programing  - *
 *          *    -  -  All Rights Reserved  -  -    *
 *          * * * * * * * * * * * * * * * * * * * * *
 */

const PageThankyouContent = {
    heading: 'THANK YOU',
    title: 'For Placing your order.',
    message: 'We have sent your order details to your inbox. Kindly, check it out.',
    searchPlaceholder: 'Search...',
    buttons: [
        {
            text: 'Wanna Shop More?',
            href: '/shop-1',
            variant: 'black',
            onClick: null
        }
    ]
};

export default PageThankyouContent; 
