/*
 *          M""""""""`M            dP
 *          Mmmmmm   .M            88
 *          MMMMP  .MMM  dP    dP  88  .dP   .d8888b.
 *          MMP  .MMMMM  88    88  88888"    88'  `88
 *          M' .MMMMMMM  88.  .88  88  `8b.  88.  .88
 *          M         M  `88888P'  dP   `YP  `88888P'
 *          MMMMMMMMMMM    -*-  Created by Zuko  -*-
 *
 *          * * * * * * * * * * * * * * * * * * * * *
 *          * -    - -   F.R.E.E.M.I.N.D   - -    - *
 *          * -  Copyright © 2024 (Z) Programing  - *
 *          *    -  -  All Rights Reserved  -  -    *
 *          * * * * * * * * * * * * * * * * * * * * *
 */

import React from 'react';

const AboutContainer = () => {

    return (
        <section className="about style_two">
            <div className="large_font">
                <h2 className="floating_element text-dark-200 d-flex justify-content-center">About</h2>
            </div>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5 col-md-5 col-sm-6">
                        <div className="about_image">
                            {/* <img src="images/about/e1.svg" alt="About" class="line_frame_2" /> */}
                            <img src="images/about/a2.jpg" alt="img" className="abt_img_1" />
                            <img src="images/about/a3.jpg" alt="img" className="abt_img_2" />
                            <img src="images/about/e1.svg" alt="About" className="line_frame_1" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-7 col-sm-6">
                        <div className="about_text_inner">
                            <div className="about_text mt-5">
                                <h2 className="text-white">ABOUT MRITTIK ARCHITECTS</h2>
                                <p>Mrittik Architects is a full-service design firm providing architecture, master planning, urban design, interior architecture, space planning and programming. Our portfolio of completed work includes highly acclaimed and award-winning projects for clients.</p>
                            </div>

                            <div className="about_progress_box">
                                <div className="progress-classic position-relative mb-4">
                                    <div className="progress-label fw-semibold mb-2">Structure Design</div>
                                    <div className="progress overflow-visible"
                                        style={{ height: '4px' }}>
                                        <div className="progress-bar overflow-visible" role="progressbar" aria-label="Red example" style={{ width: '60%' }} aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"><span className="progress-value fw-semibold">60%</span></div>
                                    </div>
                                </div>

                                <div className="progress-classic position-relative mb-4">
                                    <div className="progress-label fw-semibold mb-2">Interior Design</div>
                                    <div className="progress overflow-visible" style={{ height: '4px' }}>
                                        <div className="progress-bar overflow-visible" role="progressbar" aria-label="Red example" style={{ width: '60%' }} aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"><span className="progress-value fw-semibold">60%</span></div>
                                    </div>
                                </div>

                                <div className="progress-classic position-relative">
                                    <div className="progress-label fw-semibold mb-2">Landscape Design</div>
                                    <div className="progress overflow-visible" style={{ height: '4px' }}>
                                        <div className="progress-bar overflow-visible" role="progressbar" aria-label="Red example" style={{ width: '60%' }} aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"><span className="progress-value fw-semibold">60%</span></div>
                                    </div>
                                </div>
                            </div>
                            <div className="abt_author d-flex align-items-center gap-3 flex-wrap mt-5">
                                <div className="abt_author_pic flex-shrink-0">
                                    <img src="images/author.png" alt="img" />
                                </div>
                                <div className="abt_author_info flex-shrink-0">
                                    <h5>Maxim Alexhander</h5>
                                    <p className="mb-0">CEO, Mrittik Architecture</p>
                                </div>
                                <div className="abt_author_signature ps-3">
                                    <img src="images/sign.png" alt="signature" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutContainer;
