/*
 *          M""""""""`M            dP
 *          Mmmmmm   .M            88
 *          MMMMP  .MMM  dP    dP  88  .dP   .d8888b.
 *          MMP  .MMMMM  88    88  88888"    88'  `88
 *          M' .MMMMMMM  88.  .88  88  `8b.  88.  .88
 *          M         M  `88888P'  dP   `YP  `88888P'
 *          MMMMMMMMMMM    -*-  Created by Zuko  -*-
 *
 *          * * * * * * * * * * * * * * * * * * * * *
 *          * -    - -   F.R.E.E.M.I.N.D   - -    - *
 *          * -  Copyright © 2024 (Z) Programing  - *
 *          *    -  -  All Rights Reserved  -  -    *
 *          * * * * * * * * * * * * * * * * * * * * *
 */

import React from 'react';
import {Link} from 'react-router-dom';

const SideNav = () => {
    return (
        <div className="mr_menu">
            <div className="mr_menu_overlay"></div>
            <div className="logo_left_menu"><a href="/" className="light_logo"><img src="images/logo-circle.svg" alt="logo" /></a></div>

            {/* MainMenu */}
            <ul className="main_menu">
                <li className="menu-item menu-item-has-children active"><Link to="/">Home</Link>
                    <ul className="sub-menu">
                        <li className="menu-item"><Link to="/">Home One</Link></li>
                        <li className="menu-item"><Link to="/home-2">Home Two</Link></li>
                        <li className="menu-item"><Link to="/home-3">Home Three</Link></li>
                        <li className="menu-item"><Link to="/home-4">Home Four</Link></li>
                        <li className="menu-item"><Link to="/home-5">Home Five</Link></li>
                        <li className="menu-item"><Link to="/home-6">Home Six</Link></li>
                        <li className="menu-item"><Link to="/home-7">Home Seven</Link></li>
                        <li className="menu-item"><Link to="/home-8">Home Eight</Link></li>
                        <li className="menu-item"><Link to="/home-9">Home Nine</Link></li>
                        <li className="menu-item active"><Link to="/home-10">Home Ten</Link></li>
                    </ul>
                    <span className="submenu_opener"><i className="bi bi-chevron-right"></i></span></li>
                <li className="menu-item menu-item-has-children"><Link to="#">Gallery</Link>
                    <ul className="sub-menu">
                        <li className="menu-item"><Link to="/project-1">Projects One</Link></li>
                        <li className="menu-item"><Link to="/project-2">Projects Two</Link></li>
                        <li className="menu-item"><Link to="/project-3">Peojects Three</Link></li>
                        <li className="menu-item"><Link to="/project-details">Peoject Details</Link></li>
                        <li className="menu-item"><Link to="/project-details-2">Peoject Details Two</Link></li>
                    </ul>
                    <span className="submenu_opener"><i className="bi bi-chevron-right"></i></span></li>
                <li className="menu-item menu-item-has-children"><Link to="#">Pages</Link>
                    <ul className="sub-menu">
                        <li className="menu-item"><Link to="/about">About Us</Link></li>
                        <li className="menu-item menu-item-has-children"><Link to="#">Team</Link>
                            <ul className="sub-menu">
                                <li className="menu-item"><Link to="/team">Our Team</Link></li>
                                <li className="menu-item"><Link to="/team-details">Team Details</Link></li>
                            </ul>
                            <span className="submenu_opener"><i className="bi bi-chevron-right"></i></span></li>
                        <li className="menu-item menu-item-has-children"><Link to="#">Services</Link>
                            <ul className="sub-menu">
                                <li className="menu-item"><Link to="/service-1">Service One</Link></li>
                                <li className="menu-item"><Link to="/service-2">Service Two</Link></li>
                                <li className="menu-item"><Link to="/service-details">Service Details</Link></li>
                            </ul>
                            <span className="submenu_opener"><i className="bi bi-chevron-right"></i></span></li>
                        <li className="menu-item menu-item-has-children"><Link to="#">Shop</Link>
                            <ul className="sub-menu">
                                <li className="menu-item"><Link to="/shop-1">Shop One</Link></li>
                                <li className="menu-item"><Link to="/shop-2">Shop Two</Link></li>
                                <li className="menu-item"><Link to="/shop-3">Shop Three</Link></li>
                                <li className="menu-item"><Link to="/shop-product">Product Details</Link></li>
                                <li className="menu-item"><Link to="/shop-cart">Shop Cart</Link></li>
                                <li className="menu-item"><Link to="/shop-checkout">Checkout</Link></li>
                                <li className="menu-item"><Link to="/login">Login</Link></li>
                            </ul>
                            <span className="submenu_opener"><i className="bi bi-chevron-right"></i></span></li>
                        <li className="menu-item"><Link to="/404">404 Page</Link></li>
                        <li className="menu-item"><Link to="/coming-soon">Coming Soon</Link></li>
                    </ul>
                    <span className="submenu_opener"><i className="bi bi-chevron-right"></i></span></li>
                <li className="menu-item"><Link to="/contact">Contacts</Link></li>
                <li className="menu-item menu-item-has-children"><Link to="#">Blog</Link>
                    <ul className="sub-menu">
                        <li className="menu-item"><Link to="/blog">Blog</Link></li>
                        <li className="menu-item"><Link to="/blog-details">Blog Details</Link></li>
                    </ul>
                    <span className="submenu_opener"><i className="bi bi-chevron-right"></i></span></li>
            </ul>

            {/* <!-- Footer--> */}
            <footer className="footer p-0">
                <div className="footer_inner pb-0">
                    <div className="footer_elements d-flex align-items-center justify-content-center">
                        <div className="footer_elements_inner">
                            <div className="footer_social">
                                <ul className="social_list justify-content-center">
                                    <li className="facebook"><a href="#"><i className="bi bi-facebook"></i></a></li>
                                    <li className="twitter"><a href="#"><i className="bi bi-twitter"></i></a></li>
                                    <li className="instagram"><a href="#"><i className="bi bi-instagram"></i></a></li>
                                    <li className="youbetube"><a href="#"><i className="bi bi-youtube"></i></a></li>
                                </ul>
                            </div>
                            <div className="terms_condition">
                                <ul>
                                    <li><Link to="#">Terms</Link></li>
                                    <li><Link to="#">Condition</Link></li>
                                    <li><Link to="#">Policy</Link></li>
                                </ul>
                            </div>
                            <div className="copyright">
                                <p>Mrittik 2023. All Rights Reserved</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default SideNav;
