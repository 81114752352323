/*
 *          M""""""""`M            dP
 *          Mmmmmm   .M            88
 *          MMMMP  .MMM  dP    dP  88  .dP   .d8888b.
 *          MMP  .MMMMM  88    88  88888"    88'  `88
 *          M' .MMMMMMM  88.  .88  88  `8b.  88.  .88
 *          M         M  `88888P'  dP   `YP  `88888P'
 *          MMMMMMMMMMM    -*-  Created by Zuko  -*-
 *
 *          * * * * * * * * * * * * * * * * * * * * *
 *          * -    - -   F.R.E.E.M.I.N.D   - -    - *
 *          * -  Copyright © 2024 (Z) Programing  - *
 *          *    -  -  All Rights Reserved  -  -    *
 *          * * * * * * * * * * * * * * * * * * * * *
 */

export const menuItems = [
  {
    title: "Home",
    path: "/",
    children: [
      { title: "Home One", path: "/" },
      { title: "Home Two", path: "/home-2" },
      { title: "Home Three", path: "/home-3" },
      { title: "Home Four", path: "/home-4" },
      { title: "Home Five", path: "/home-5" },
      { title: "Home Six", path: "/home-6" },
      { title: "Home Seven", path: "/home-7" },
      { title: "Home Eight", path: "/home-8" },
      { title: "Home Nine", path: "/home-9" },
      { title: "Home Ten", path: "/home-10" }
    ]
  },
  {
    title: "Gallery",
    path: "/",
    children: [
      { title: "Projects One", path: "/project-1" },
      { title: "Projects Two", path: "/project-2" },
      { title: "Projects Three", path: "/project-3" },
      { title: "Project Details", path: "/project-details" },
      { title: "Project Details Two", path: "/project-details-2" }
    ]
  },
  {
    title: "Services",
    path: "/",
    children: [
      { title: "Service One", path: "/service-1" },
      { title: "Service Two", path: "/service-2" },
      { title: "Service Details", path: "/service-details" }
    ]
  },
  {
    hidden: !globalThis?.isDevelopmentMode,
    title: "Pages",
    path: "/about",
    children: [
      { title: "About Us", path: "/about" },
      {
        title: "Team",
        path: "/",
        children: [
          { title: "Our Team", path: "/team" },
          { title: "Team Details", path: "/team-details" }
        ]
      },
      { title: "404 Page", path: "/404" },
      { title: "Coming Soon", path: "/coming-soon" }
    ]
  },
  {
    hidden: true,
    title: "Shop",
    path: "/",
    children: [
      { title: "Shop One", path: "/shop-1" },
      { title: "Shop Two", path: "/shop-2" },
      { title: "Shop Three", path: "/shop-3" },
      { title: "Product Details", path: "/shop-product" },
      { title: "Shop Cart", path: "/shop-cart" },
      { title: "Checkout", path: "/shop-checkout" },
      { title: "Login", path: "/login" }
    ]
  },
  {
    title: "Blog",
    path: "/blog",
    children: [
      { title: "Blog", path: "/blog" },
      { title: "Blog Details", path: "/blog-details" }
    ]
  },
  { title: "About Us", path: "/about" },
  { title: "Contacts", path: "/contact" },

]; 
