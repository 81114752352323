/*
 *          M""""""""`M            dP
 *          Mmmmmm   .M            88
 *          MMMMP  .MMM  dP    dP  88  .dP   .d8888b.
 *          MMP  .MMMMM  88    88  88888"    88'  `88
 *          M' .MMMMMMM  88.  .88  88  `8b.  88.  .88
 *          M         M  `88888P'  dP   `YP  `88888P'
 *          MMMMMMMMMMM    -*-  Created by Zuko  -*-
 *
 *          * * * * * * * * * * * * * * * * * * * * *
 *          * -    - -   F.R.E.E.M.I.N.D   - -    - *
 *          * -  Copyright © 2024 (Z) Programing  - *
 *          *    -  -  All Rights Reserved  -  -    *
 *          * * * * * * * * * * * * * * * * * * * * *
 */

import $ from 'jquery';
import React, { useEffect } from 'react';

const Loader = () => {
    useEffect(() => {
        // Preloader
        setTimeout(function () {
            $('#preloader').addClass('hide');
        }, 300);

        $("#preloader").append('<div class="hide-loader">Hide Preloader</div>');
        $('.hide-loader').click(function (e) {
            $(this).parent().addClass('hide');
        });
        if(!globalThis.showPreloader){
          globalThis.showPreloader = () => $('#preloader').removeClass('hide');
        }
    }, []);

    return (
        <div id="preloader">
            <div className="preloader-inner">
                <div className="spinner"></div>
                <div className="loading-text">
                    <span data-preloader-text="L" className="characters">L</span>

                    <span data-preloader-text="A" className="characters">A</span>

                    <span data-preloader-text="L" className="characters">L</span>

                    <span data-preloader-text="I" className="characters">I</span>

                    <span data-preloader-text="T" className="characters">T</span>

                    <span data-preloader-text="A" className="characters">A</span>

                </div>
            </div>
        </div>

    );
};

export default Loader;
