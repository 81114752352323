
/*
 *          M""""""""`M            dP
 *          Mmmmmm   .M            88
 *          MMMMP  .MMM  dP    dP  88  .dP   .d8888b.
 *          MMP  .MMMMM  88    88  88888"    88'  `88
 *          M' .MMMMMMM  88.  .88  88  `8b.  88.  .88
 *          M         M  `88888P'  dP   `YP  `88888P'
 *          MMMMMMMMMMM    -*-  Created by Zuko  -*-
 *
 *          * * * * * * * * * * * * * * * * * * * * *
 *          * -    - -   F.R.E.E.M.I.N.D   - -    - *
 *          * -  Copyright © 2024 (Z) Programing  - *
 *          *    -  -  All Rights Reserved  -  -    *
 *          * * * * * * * * * * * * * * * * * * * * *
 */

const ServiceCard03 = ({ data }) => {
    const { img01, title, text } = data;
    return (
        <div className="col-lg-4" >
            <div className="icon_box">
                <div className="icon_box_inner">
                    <img src={img01} alt="Icon Box" />
                    <h4 className="text-white"><a href="/service-1">{title}</a></h4>
                    <p className="text-gray-600">{text}</p>
                    <div className="arrow_effect">
                        <a href="/service-1"><span className="crossline1"></span><span className="crossline2"></span></a>
                    </div>
                </div>
            </div>
        </div >

    );
};

export default ServiceCard03;
