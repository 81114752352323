/*
 *          M""""""""`M            dP
 *          Mmmmmm   .M            88
 *          MMMMP  .MMM  dP    dP  88  .dP   .d8888b.
 *          MMP  .MMMMM  88    88  88888"    88'  `88
 *          M' .MMMMMMM  88.  .88  88  `8b.  88.  .88
 *          M         M  `88888P'  dP   `YP  `88888P'
 *          MMMMMMMMMMM    -*-  Created by Zuko  -*-
 *
 *          * * * * * * * * * * * * * * * * * * * * *
 *          * -    - -   F.R.E.E.M.I.N.D   - -    - *
 *          * -  Copyright © 2024 (Z) Programing  - *
 *          *    -  -  All Rights Reserved  -  -    *
 *          * * * * * * * * * * * * * * * * * * * * *
 */

import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../Pages/Shared/Footers/Footer';
import Header from '../Pages/Shared/Headers/Header';
import SectionGridLines from '../components/SectionGridLines';
import Loader from '@/Pages/Shared/Loader.jsx';

const HomeLayout = () => {
    return (
        <>
          <Loader/>
          <Header />
            <Outlet />
            <SectionGridLines />
            <Footer />
        </>
    );
};

export default HomeLayout;
