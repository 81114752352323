/*
 *          M""""""""`M            dP
 *          Mmmmmm   .M            88
 *          MMMMP  .MMM  dP    dP  88  .dP   .d8888b.
 *          MMP  .MMMMM  88    88  88888"    88'  `88
 *          M' .MMMMMMM  88.  .88  88  `8b.  88.  .88
 *          M         M  `88888P'  dP   `YP  `88888P'
 *          MMMMMMMMMMM    -*-  Created by Zuko  -*-
 *
 *          * * * * * * * * * * * * * * * * * * * * *
 *          * -    - -   F.R.E.E.M.I.N.D   - -    - *
 *          * -  Copyright © 2024 (Z) Programing  - *
 *          *    -  -  All Rights Reserved  -  -    *
 *          * * * * * * * * * * * * * * * * * * * * *
 */

import React from 'react';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from "swiper/react";
import SliderCard06 from '../../components/SliderElements/SliderCard06';
import SliderCard07 from '../../components/SliderElements/SliderCard07';

const Sliders07 = () => {
    return (
        <div className="theme_slider_6">
            <div className="swiper swiper_theme_slider_6">
                <Swiper
                    modules={[Navigation]}
                    slidesPerView={3}
                    loop={true}
                    initialSlide={1}
                    centeredSlides={true}
                    navigation={{
                        prevEl: '.swiper-button-prev',
                        nextEl: '.swiper-button-next',
                    }}
                >
                    <SwiperSlide>
                        <SliderCard07 img='images/slider/grid/1.jpg' />
                    </SwiperSlide>
                    <SwiperSlide>
                        <SliderCard07 img='images/slider/grid/2.jpg' />
                    </SwiperSlide>
                    <SwiperSlide>
                        <SliderCard07 img='images/slider/grid/3.jpg' />
                    </SwiperSlide>
                    <SwiperSlide>
                        <SliderCard07 img='images/slider/grid/4.jpg' />
                    </SwiperSlide>
                    <SwiperSlide>
                        <SliderCard07 img='images/slider/grid/5.jpg' />
                    </SwiperSlide>
                </Swiper>
            </div>

            <div className="swiper_theme_slider_6_body">
                <SliderCard06 title='THE FUTURE ARCHITECTURE IS HERE' />
            </div>

            <div className="swiper swiper_theme_slider_6 second_row">
                <Swiper
                    modules={[Navigation]}
                    slidesPerView={3}
                    loop={true}
                    initialSlide={1}
                    centeredSlides={true}
                    navigation={{
                        prevEl: '.swiper-button-prev',
                        nextEl: '.swiper-button-next',
                    }}
                >
                    <SwiperSlide>
                        <SliderCard07 img='images/slider/grid/4.jpg' />
                    </SwiperSlide>
                    <SwiperSlide>
                        <SliderCard07 img='images/slider/grid/5.jpg' />
                    </SwiperSlide>
                    <SwiperSlide>
                        <SliderCard07 img='images/slider/grid/6.jpg' />
                    </SwiperSlide>
                    <SwiperSlide>
                        <SliderCard07 img='images/slider/grid/1.jpg' />
                    </SwiperSlide>
                    <SwiperSlide>
                        <SliderCard07 img='images/slider/grid/2.jpg' />
                    </SwiperSlide>

                </Swiper>
            </div>

            {/* <!-- Add Buttons --> */}
            <div className="swiper-button-prev details_link l-dir pagination-previous"><a href="#"><span className="link_icon"><span className="line"></span> <span className="circle"></span><span className="dot"></span></span></a></div>
            <div className="swiper-button-next details_link r-dir pagination-next"><a href="#"><span className="link_icon"><span className="line"></span> <span className="circle"></span><span className="dot"></span></span></a></div>

        </div >
    );
};

export default Sliders07;
