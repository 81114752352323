/*
 *          M""""""""`M            dP
 *          Mmmmmm   .M            88
 *          MMMMP  .MMM  dP    dP  88  .dP   .d8888b.
 *          MMP  .MMMMM  88    88  88888"    88'  `88
 *          M' .MMMMMMM  88.  .88  88  `8b.  88.  .88
 *          M         M  `88888P'  dP   `YP  `88888P'
 *          MMMMMMMMMMM    -*-  Created by Zuko  -*-
 *
 *          * * * * * * * * * * * * * * * * * * * * *
 *          * -    - -   F.R.E.E.M.I.N.D   - -    - *
 *          * -  Copyright © 2024 (Z) Programing  - *
 *          *    -  -  All Rights Reserved  -  -    *
 *          * * * * * * * * * * * * * * * * * * * * *
 */
import React, { useEffect } from 'react';
import { useLocalStorage } from '@/hooks/useLocalStorage';

const ThemeSwitcher = () => {
  const [theme, setTheme] = useLocalStorage('theme', 'dark');

  useEffect(() => {
    // Apply the initial theme
    toggleTheme();
  }, [theme]);

  const toggleTheme = () => {
    if (theme === 'light') {
      document.body.classList.add('bg-white');
      document.body.classList.remove('bg-dark');
    } else {
      document.body.classList.remove('bg-white');
      document.body.classList.add('bg-dark');
    }
  };

  const handleThemeToggle = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
  };

  return (
  <div id="mode_switcher" className="cursor-pointer" onClick={handleThemeToggle}>
      {theme === 'light' ? (
      <span>
          <i className="bi bi-moon-fill"></i>
        </span>
      ) : (
       <span>
          <i className="bi bi-sun-fill"></i>
        </span>
       )}
    </div>
  );
};

export default ThemeSwitcher;
