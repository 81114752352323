/*
 *          M""""""""`M            dP
 *          Mmmmmm   .M            88
 *          MMMMP  .MMM  dP    dP  88  .dP   .d8888b.
 *          MMP  .MMMMM  88    88  88888"    88'  `88
 *          M' .MMMMMMM  88.  .88  88  `8b.  88.  .88
 *          M         M  `88888P'  dP   `YP  `88888P'
 *          MMMMMMMMMMM    -*-  Created by Zuko  -*-
 *
 *          * * * * * * * * * * * * * * * * * * * * *
 *          * -    - -   F.R.E.E.M.I.N.D   - -    - *
 *          * -  Copyright © 2024 (Z) Programing  - *
 *          *    -  -  All Rights Reserved  -  -    *
 *          * * * * * * * * * * * * * * * * * * * * *
 */

import $ from 'jquery';
import React, { useEffect } from 'react';
import './Header02Nav.css';
import {Link} from 'react-router-dom';

const Header02Nav = (props) => {
    useEffect(() => {
        // Sub-Menu Open On-Click
        $('.mr_menu_02 ul.main_menu li.menu-item-has-children .submenu_opener').on("click", function (e) {
            $(this).parent().toggleClass('nav_open');
          $(this).parent().hasClass('nav_open') ? $(this).siblings('ul').slideUp() : $(this).siblings('ul').slideDown();
            e.stopPropagation();
            e.preventDefault();
        });

    }, []);

    return (
        <div className="bg-dark offcanvas offcanvas-start_02" tabIndex="-1" id={props.id} aria-labelledby="offcanvasNavbarLabel">
            <div className="mr_menu_02">
                <div className="mr_menu_02_overlay"></div>
                <button type="button" className="mr_menu_02_close" data-bs-dismiss="offcanvas" aria-label="Close"><i className="bi bi-x-lg"></i></button>
                <div className="logo"><a src="index" className="light_logo"><img src="images/logo-light.svg" alt="logo" /></a><a src="index" className="dark_logo"><img src="images/logo-dark.svg" alt="logo" /></a></div>
                <div className="mr_navmenu">
                    <ul className="main_menu">
                        <li className="menu-item menu-item-has-children">
                            <Link to="/">Home</Link>
                            <ul className="sub-menu">
                                <li className="menu-item"><Link to="/">Home One</Link></li>
                                <li className="menu-item"><Link to="/home-2">Home Two</Link></li>
                                <li className="menu-item"><Link to="/home-3">Home Three</Link></li>
                                <li className="menu-item"><Link to="/home-4">Home Four</Link></li>
                                <li className="menu-item"><Link to="/home-5">Home Five</Link></li>
                                <li className="menu-item"><Link to="/home-6">Home Six</Link></li>
                                <li className="menu-item"><Link to="/home-7">Home Seven</Link></li>
                            </ul>
                            <span className="submenu_opener"><i className="bi bi-chevron-right"></i></span>
                        </li>
                        <li className="menu-item menu-item-has-children">
                            <Link to="/">Gallery</Link>
                            <ul className="sub-menu">
                                <li className="menu-item"><Link to="/project-1">Projects One</Link></li>
                                <li className="menu-item"><Link to="/project-2">Projects Two</Link></li>
                                <li className="menu-item"><Link to="/project-3">Peojects Three</Link></li>
                                <li className="menu-item"><Link to="/project-details">Peoject Details</Link></li>
                                <li className="menu-item"><Link to="/project-details-2">Peoject Details Two</Link></li>
                            </ul>
                            <span className="submenu_opener"><i className="bi bi-chevron-right"></i></span>
                        </li>
                        <li className="menu-item menu-item-has-children">
                            <Link to="/">Pages</Link>
                            <ul className="sub-menu">
                                <li className="menu-item"><Link to="/about">About Us</Link></li>
                                <li className="menu-item menu-item-has-children">
                                    <Link to="/">Team</Link>
                                    <ul className="sub-menu">
                                        <li className="menu-item"><Link to="/team">Our Team</Link></li>
                                        <li className="menu-item"><Link to="/team-details">Team Details</Link></li>
                                    </ul>
                                    <span className="submenu_opener"><i className="bi bi-chevron-right"></i></span>
                                </li>
                                <li className="menu-item menu-item-has-children">
                                    <Link to="/">Services</Link>
                                    <ul className="sub-menu">
                                        <li className="menu-item"><Link to="/service-1">Service One</Link></li>
                                        <li className="menu-item"><Link to="/service-2">Service Two</Link></li>
                                        <li className="menu-item"><Link to="/service-details">Service Details</Link></li>
                                    </ul>
                                    <span className="submenu_opener"><i className="bi bi-chevron-right"></i></span>
                                </li>
                                <li className="menu-item menu-item-has-children">
                                    <Link to="/">Shop</Link>
                                    <ul className="sub-menu">
                                        <li className="menu-item"><Link to="/shop-1">Shop One</Link></li>
                                        <li className="menu-item"><Link to="/shop-2">Shop Two</Link></li>
                                        <li className="menu-item"><Link to="/shop-3">Shop Three</Link></li>
                                        <li className="menu-item"><Link to="/shop-product">Product Details</Link></li>
                                        <li className="menu-item"><Link to="/shop-cart">Shop Cart</Link></li>
                                        <li className="menu-item"><Link to="/shop-checkout">Checkout</Link></li>
                                        <li className="menu-item"><Link to="/login">Login</Link></li>
                                    </ul>
                                    <span className="submenu_opener"><i className="bi bi-chevron-right"></i></span>
                                </li>
                                <li className="menu-item"><Link to="/404">404 Page</Link></li>
                                <li className="menu-item"><Link to="/coming-soon">Coming Soon</Link></li>
                            </ul>
                            <span className="submenu_opener"><i className="bi bi-chevron-right"></i></span>
                        </li>
                        <li className="menu-item"><Link to="/contact">Contacts</Link></li>
                        <li className="menu-item menu-item-has-children">
                            <Link to="/">Blog</Link>
                            <ul className="sub-menu">
                                <li className="menu-item"><Link to="/blog">Blog</Link></li>
                                <li className="menu-item"><Link to="/blog-details">Blog Details</Link></li>
                            </ul>
                            <span className="submenu_opener"><i className="bi bi-chevron-right"></i></span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Header02Nav;
