/*
 *          M""""""""`M            dP
 *          Mmmmmm   .M            88
 *          MMMMP  .MMM  dP    dP  88  .dP   .d8888b.
 *          MMP  .MMMMM  88    88  88888"    88'  `88
 *          M' .MMMMMMM  88.  .88  88  `8b.  88.  .88
 *          M         M  `88888P'  dP   `YP  `88888P'
 *          MMMMMMMMMMM    -*-  Created by Zuko  -*-
 *
 *          * * * * * * * * * * * * * * * * * * * * *
 *          * -    - -   F.R.E.E.M.I.N.D   - -    - *
 *          * -  Copyright © 2024 (Z) Programing  - *
 *          *    -  -  All Rights Reserved  -  -    *
 *          * * * * * * * * * * * * * * * * * * * * *
 */

import React from 'react';
import AboutContainer from '../../containers/AboutContainers/AboutContainer';
import Blogs from '../../containers/Blogs';
import FormContainer from '../../containers/FormContainer';
import FunFacts from '../../containers/FunFacts/FunFacts';
import ProjectGallery from '../../containers/ProjectContainers/ProjectGallery';
import RunningProjects from '../../containers/RunningProjects';
import Services from '../../containers/Services';
import Sliders08 from '../../containers/SliderContainers/Sliders08';
import Testimonials from '../../containers/Testimonials';

const Home08 = () => {
    return (
        <div className="wrapper">
            <Sliders08 />
            <Services />
            <AboutContainer />
            <FunFacts className='funfacts bg_1' />
            <RunningProjects />
            <ProjectGallery />
            <Testimonials />
            <Blogs />
            <FormContainer />
        </div>
    );
};

export default Home08;
