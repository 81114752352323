/*
 *          M""""""""`M            dP
 *          Mmmmmm   .M            88
 *          MMMMP  .MMM  dP    dP  88  .dP   .d8888b.
 *          MMP  .MMMMM  88    88  88888"    88'  `88
 *          M' .MMMMMMM  88.  .88  88  `8b.  88.  .88
 *          M         M  `88888P'  dP   `YP  `88888P'
 *          MMMMMMMMMMM    -*-  Created by Zuko  -*-
 *
 *          * * * * * * * * * * * * * * * * * * * * *
 *          * -    - -   F.R.E.E.M.I.N.D   - -    - *
 *          * -  Copyright © 2024 (Z) Programing  - *
 *          *    -  -  All Rights Reserved  -  -    *
 *          * * * * * * * * * * * * * * * * * * * * *
 */

import React from 'react';
import ProjectDetailsInner02 from '../../components/ProjectElements/ProjectDetailsInner02';
import SideBar02 from '../../components/SideBar02';

const ProjectDetailsContainer02 = () => {
    return (
        <section className="project-details bg-dark-200">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-8">
                        <div className="post-image">
                            <img src="images/portfolio/details/sl-1.jpg" alt="image" />
                        </div>
                        <ProjectDetailsInner02 />
                    </div>

                    <div className="col-lg-4 col-md-4">
                        <SideBar02 />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ProjectDetailsContainer02;
