/**
 * --------------------------------------------------------------------------
 *
 * --------------------------------------------------------------------------
 * @PROJECT    : Lalita
 * @AUTHOR     : Zuko <https://github.com/tansautn>
 * @LINK       : https://www.zuko.pro/
 * @FILE       : AboutUs.jsx

 * @CREATED    : 10:57 , 02/Nov/2024
 */
export default {
  "slogan":"Our organizational sprit believes that where design meets passion & love with space, good architecture happens here",
  "title": "About Us",
  "content": "",
  "pageHeader": "which basically form, request deug. but nothing relate to Servcies",
}
