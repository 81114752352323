/*
 *          M""""""""`M            dP
 *          Mmmmmm   .M            88
 *          MMMMP  .MMM  dP    dP  88  .dP   .d8888b.
 *          MMP  .MMMMM  88    88  88888"    88'  `88
 *          M' .MMMMMMM  88.  .88  88  `8b.  88.  .88
 *          M         M  `88888P'  dP   `YP  `88888P'
 *          MMMMMMMMMMM    -*-  Created by Zuko  -*-
 *
 *          * * * * * * * * * * * * * * * * * * * * *
 *          * -    - -   F.R.E.E.M.I.N.D   - -    - *
 *          * -  Copyright © 2024 (Z) Programing  - *
 *          *    -  -  All Rights Reserved  -  -    *
 *          * * * * * * * * * * * * * * * * * * * * *
 */

import React from 'react';
import { Link } from "react-router-dom";

const CuponCode = () => {
    return (
        <div className="couponcart">
            <div className="set_coupon">
                <div className="form-group">
                    <input type="text" className="form-control" name="coupon" placeholder="Coupon Code" />
                </div>
                <div className="btn_group">
                    <input className="btn olive" type="submit" value="Apply Coupon" name="submit" />
                </div>
            </div>
            <div className="cartupdate">
                <div className="btn_group">
                    <Link to='/shop-checkout'>
                        <input className="btn white" type="submit" value="Update Cart" name="submit" />
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default CuponCode;
