/*
 *          M""""""""`M            dP
 *          Mmmmmm   .M            88
 *          MMMMP  .MMM  dP    dP  88  .dP   .d8888b.
 *          MMP  .MMMMM  88    88  88888"    88'  `88
 *          M' .MMMMMMM  88.  .88  88  `8b.  88.  .88
 *          M         M  `88888P'  dP   `YP  `88888P'
 *          MMMMMMMMMMM    -*-  Created by Zuko  -*-
 *
 *          * * * * * * * * * * * * * * * * * * * * *
 *          * -    - -   F.R.E.E.M.I.N.D   - -    - *
 *          * -  Copyright © 2024 (Z) Programing  - *
 *          *    -  -  All Rights Reserved  -  -    *
 *          * * * * * * * * * * * * * * * * * * * * *
 */

import React from 'react';

const CategoryItem = ({ categoryItem }) => {
    const { id, name, href, item1, item2, item3, categoryID } = categoryItem;

    return (
        <li className="category_item">
            <div className="category_item_inner">
                {/* <Link to={`/shop-category/${categoryID}`}> */}
                <div className="cat_name"><a href="#">{name}</a></div>
                {/* </Link> */}
                <span className="cat_toggler">
                    <a data-bs-toggle="collapse" href={href}>
                        <i className="bi bi-chevron-right"></i>
                    </a>
                </span>
            </div>
            <ul id={id} className="child_category collapse">
                <li>
                    <div className="cat_name"><a href="#">{item1}</a></div>
                </li>
                {/* <>
                {
                    item1 ? <>
                                <li>
                                <div className="cat_name"><a href="#">{item1}</a></div>
                                </li> 
                            </> : <></>
                }
                </> */}

                <>
                {
                    item2 ? <>
                                <li>
                                <div className="cat_name"><a href="#">{item2}</a></div>
                                </li> 
                            </> : <></>
                }
                </>
                <>
                {
                    item3 ? <>
                                <li>
                                <div className="cat_name"><a href="#">{item3}</a></div>
                                </li> 
                            </> : <></>
                }
                </>
               
            </ul>
        </li>
    );
};

export default CategoryItem;
