/*
 *          M""""""""`M            dP
 *          Mmmmmm   .M            88
 *          MMMMP  .MMM  dP    dP  88  .dP   .d8888b.
 *          MMP  .MMMMM  88    88  88888"    88'  `88
 *          M' .MMMMMMM  88.  .88  88  `8b.  88.  .88
 *          M         M  `88888P'  dP   `YP  `88888P'
 *          MMMMMMMMMMM    -*-  Created by Zuko  -*-
 *
 *          * * * * * * * * * * * * * * * * * * * * *
 *          * -    - -   F.R.E.E.M.I.N.D   - -    - *
 *          * -  Copyright © 2024 (Z) Programing  - *
 *          *    -  -  All Rights Reserved  -  -    *
 *          * * * * * * * * * * * * * * * * * * * * *
 */

import React from 'react';

const CommentRespond = () => {
    return (
        <div className="comment-respond">
            <h3 className="comment-reply-title">Make A Comment <span className="title-line"></span></h3>
            <form className="comment-form" action="register.php" method="post">
                <p className="logged-in-as">Your email address will not be published. Required fields are marked *</p>
                <div className="form-container">
                    <div className="row">
                        <div className="col-md-6 col-lg-6">
                            <div className="form-group">
                                <input type="text" name="name" className="form-control" placeholder="Name*" required="" />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6">
                            <div className="form-group">
                                <input type="email" name="email" className="form-control" placeholder="E-mail*" required="" />
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-12">
                            <div className="form-group">
                                <textarea name="message" className="form-control" placeholder="Text Here*" required="" ></textarea>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-12">
                            <div className="btn_group">
                                <input className="btn olive" type="submit" value="Submit" name="submit" />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default CommentRespond;
